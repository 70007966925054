export const checkIfHasVariants = (item) => item.types && item.types.length > 0;

export const checkIfVariantsMissing = (item, selectedVariants) => {
  if (checkIfHasVariants(item)) {
    return (
      (item.types &&
        item.types.length > 0 &&
        selectedVariants.typesSelected.value === "") ||
      (selectedVariants.typesSelected &&
        selectedVariants.typesSelected.sizes?.length > 0 &&
        selectedVariants &&
        selectedVariants.sizeSelected.value === "") ||
      (selectedVariants.typesSelected &&
        selectedVariants.typesSelected.sizes?.length > 0 &&
        selectedVariants &&
        selectedVariants.sizeSelected.value !== "" &&
        selectedVariants.sizeSelected.colors?.length > 0 &&
        selectedVariants.colorSelected.value === "")
    );
  }
  return checkIfHasVariants(item);
};
